<script setup lang="ts">
// Imports
import { onClickOutside, useElementBounding } from '@vueuse/core';
import { useField } from 'vee-validate';

export type Select = {
  key: string | number;
  label: string;
  icon?: string;
  iconClass?: string;
  disabled?: boolean;
};

// Props & Emits
const emits = defineEmits(['selectItem']);
const props = defineProps<{
  items: Select[];
  name: string;
  placeholder?: string;
  label?: string;
  hideDetails?: boolean;
  required?: boolean;
  icon?: string;
  disabled?: boolean;
  searchable?: boolean;
}>();

const search = ref<string>('');
const button = ref<HTMLElement | null>(null);
const { left, width, height, top, y, update } = useElementBounding(button);

const inputContainer = ref<HTMLElement | null>(null);
onClickOutside(inputContainer, () => (open.value = false));
const elements = ref<HTMLElement | null>(null);
const { width: elementsWidth, height: elementsHeight, update: reload } = useElementBounding(elements);

// Data
const { value, errorMessage } = useField(props.name);
const open = ref(false);

// Computed
const itemsSearch = computed(() => {
  if (search.value === '') return props.items;
  return props.items.filter((item) => {
    return item.label.toLowerCase().includes(search.value.toLowerCase());
  });
});

// Methods
const clickItem = (item: Select) => {
  if (item.disabled || props.disabled) return;
  emits('selectItem', item.key);
  value.value = item.key;
  open.value = false;
};

const getWindowHeight = computed(() => {
  return window.innerHeight;
});

const getYPosition = computed(() => {
  if (getWindowHeight.value - y.value > elementsHeight.value + 32) {
    return `top: ${top.value + height.value}px;`;
  } else {
    return `top: ${y.value - elementsHeight.value - 16}px;`;
  }
});

const openMenu = () => {
  if (props.disabled) return;
  update();
  reload();
  open.value = !open.value;
};
</script>

<template>
  <div class="w-full" :class="[hideDetails ? '' : 'mb-[16px]']">
    <label
      v-if="label"
      :class="[errorMessage ? 'text-red-300' : 'text-gray-600', disabled ? '!text-gray-400' : '']"
      class="font-medium text-xs mb-[6px] block text-left"
    >
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>
    <div ref="inputContainer" class="w-full relative">
      <div class="relative w-full inline-block text-left">
        <div class="group" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'">
          <div
            v-if="!$slots.button"
            ref="button"
            :class="[
              errorMessage ? 'border-red-600' : 'border-gray-200  hover:ring-[1px]',
              open
                ? errorMessage
                  ? 'border-red-600 ring-red-100 ring-[2px]'
                  : 'border-primary-500 ring-green-100 ring-[2px]'
                : errorMessage
                  ? ''
                  : 'hover:border-gray-300 hover:ring-gray-100',
              disabled ? '!border-gray-100 !hover:border-none !hover:ring-none' : '',
            ]"
            class="flex z-0 h-[32px] bg-white items-center w-full justify-between border rounded-md px-2 text-sm font-medium"
            @click="openMenu()"
          >
            <p v-if="value !== undefined && value !== null" class="font-normal" :class="props.disabled ? 'text-gray-400' : 'text-gray-600'">
              {{ items.find((element) => element.key === value)?.label || '' }}
            </p>
            <p v-else class="text-gray-400 font-normal text-sm">
              {{ placeholder ?? $t('input.select') }}
            </p>
            <ui-icon :class="props.disabled ? 'stroke-gray-400' : 'stroke-gray-600'" class="ml-2 h-4 w-4" name="ChevronDown" />
          </div>
          <div v-else>
            <slot name="button" />
          </div>
        </div>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-y-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="transform opacity-100 scale-y-100"
          leave-to-class="transform opacity-0 scale-y-95"
        >
          <div
            v-if="open"
            ref="elements"
            :class="[searchable ? 'divide-y divide-gray-100' : '']"
            :style="`left: ${left - elementsWidth + width}px; width: ${width}px;};` + getYPosition"
            class="fixed max-h-[200px] origin-top z-[9999] bg-white overflow-y-auto mt-2 p-1 divide-y divide-gray-100 rounded-md shadow-lg border border-gray-200 focus:outline-none"
          >
            <input
              v-if="searchable"
              ref="input"
              v-model="search"
              aria-autocomplete="both"
              autocomplete="off"
              required
              :placeholder="$t('global.search')"
              class="p-[12px] w-full mb-1 border-gray-300 focus:border-primary-500 focus:ring-primary-100 h-[32px] text-sm autofill:bg-white bg-white outline-none focus:ring-[2px] ring-offset-0 border-[1px] text-[14px] text-gray-700 rounded-md"
              type="text"
            />
            <div class="py-1">
              <div v-for="item in itemsSearch" :key="item.key" :disabled="item.disabled" class="">
                <slot name="item" :item="item">
                  <div
                    class="select-none cursor-pointer flex items-center"
                    :class="[
                      item.disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100',
                      value === item.key ? 'bg-gray-100 text-gray-900' : 'text-gray-700 hover:bg-gray-50',
                      'block px-2 py-1.5 text-sm rounded-md',
                    ]"
                    @click="clickItem(item)"
                  >
                    <div>
                      <component :is="item.icon" v-if="item.icon" :class="item.iconClass" class="w-5 h-5 mr-4 md:mr-2 md:w-4 md:h-4" />
                    </div>

                    <p class="whitespace-nowrap text-sm">
                      {{ item.label }}
                    </p>
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <span v-if="errorMessage && !hideDetails" class="absolute right-0 top-[34px] text-red-500 text-xs">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>
